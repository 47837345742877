import { ButtonProps, makeStyles, RadioGroup, styled } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import { color, Colour } from "../theme/Design";
import { Button } from "../uiComponents";

interface VerticalStepProps {
  isActive: boolean;
  isCompleted: boolean;
  children: React.ReactNode;
  step: number;
}

export const VerticalStep = styled(({ isActive, step, isCompleted, children, ...HTMLProps }: VerticalStepProps) => (
  <div {...HTMLProps}>
    <span className="icon-wrap">
      {isCompleted && <TickIcon />}
      {!isCompleted && <span className="icon">{step}</span>}
    </span>
    {children}
  </div>
))((props: VerticalStepProps) => ({
  "&:before": {
    content: '""',
    width: "1px",
    background: "#E5E9ED",
    height: "calc(100% - 40px)",
    position: "absolute",
    left: "20px",
    top: "43px",
  },
  "& h5": {
    marginBottom: 20,
  },
  "& .icon": {
    width: 40,
    height: 40,
    background: props.isActive ? color.blue900 : "#E5E9ED",
    color: props.isActive ? color.white : "currentColor",
    borderRadius: "50%",
    textAlign: "center",
    lineHeight: "40px",
    display: "block",
    fontSize: 18,
    fontWeight: 600,
  },
  padding: "10px 42px 14px 70px",
  position: "relative",
  "& .icon-wrap": {
    position: "absolute",
    left: 0,
    top: 3,
    "& svg": {
      width: 40,
      height: 40,
    },
  },
}));

export const VerticalStepContainer = styled("div")({
  maxWidth: 730,
});

interface TickIconProps {}

const TickIcon = styled(({ ...HTMLProps }: TickIconProps) => (
  <svg {...HTMLProps} width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20.5" cy="20.5" r="19.5" fill="#1F313D" stroke="#1F313D" strokeWidth="2" />
    <path
      d="M28.4353 16.5972L26.5728 14.7294C26.5102 14.6667 26.4253 14.6314 26.3367 14.6314C26.2481 14.6314 26.1631 14.6667 26.1005 14.7294L18.2343 22.601C18.1717 22.6637 18.0867 22.699 17.9981 22.699C17.9095 22.699 17.8246 22.6637 17.762 22.601L15.8995 20.7331C15.8369 20.6704 15.7519 20.6352 15.6633 20.6352C15.5747 20.6352 15.4898 20.6704 15.4272 20.7331L13.5647 22.601C13.4345 22.7312 13.4345 22.9424 13.5647 23.0726L17.762 27.2706C17.8246 27.3333 17.9095 27.3686 17.9981 27.3686C18.0867 27.3686 18.1717 27.3333 18.2343 27.2706L28.4353 17.0642C28.5624 16.9345 28.5624 16.7269 28.4353 16.5972Z"
      fill="white"
    />
    <mask id="mask0" maskUnits="userSpaceOnUse" x="13" y="14" width="16" height="14">
      <path
        d="M28.4353 16.5972L26.5728 14.7294C26.5102 14.6667 26.4253 14.6314 26.3367 14.6314C26.2481 14.6314 26.1631 14.6667 26.1005 14.7294L18.2343 22.601C18.1717 22.6637 18.0867 22.699 17.9981 22.699C17.9095 22.699 17.8246 22.6637 17.762 22.601L15.8995 20.7331C15.8369 20.6704 15.7519 20.6352 15.6633 20.6352C15.5747 20.6352 15.4898 20.6704 15.4272 20.7331L13.5647 22.601C13.4345 22.7312 13.4345 22.9424 13.5647 23.0726L17.762 27.2706C17.8246 27.3333 17.9095 27.3686 17.9981 27.3686C18.0867 27.3686 18.1717 27.3333 18.2343 27.2706L28.4353 17.0642C28.5624 16.9345 28.5624 16.7269 28.4353 16.5972Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)" />
  </svg>
))({});

interface HeadingProps {
  heading: string;
  hideBorder?: boolean;
  btnFunction?: () => void;
  showBtn?: boolean;
  buttonText?: string;
}
export const Heading = styled(
  ({ heading, hideBorder, btnFunction, showBtn, buttonText, ...HTMLProps }: HeadingProps) => (
    <div {...HTMLProps}>
      <h5>{heading}</h5>
      {!hideBorder && <hr />}
      {showBtn && (
        <span>
          <Button onClick={() => btnFunction && btnFunction()} size="small" variant="text" themeType="light">
            {buttonText}
          </Button>
        </span>
      )}
    </div>
  ),
)({
  marginBottom: 16,
  display: "flex",
  alignItems: "center",
  "& hr": {
    flex: "auto",
    margin: 0,
  },
  "& h5": {
    margin: 0,
    whiteSpace: "nowrap",
    marginRight: 20,
  },
  "& > span": {
    minWidth: 30,
    textAlign: "right",
    marginLeft: 20,
  },
  "& button": {
    padding: "5px !important",
    minWidth: "0 !important",
    border: "0 !important",
  },
});

interface AddButtonprops extends ButtonProps {
  buttonText: string;
}
export const AddButton = styled(({ buttonText, ...HTMLProps }: AddButtonprops) => (
  <Button {...HTMLProps} size="small" themeType="light" variant="outlined">
    <AddIcon /> {buttonText}
  </Button>
))({
  padding: "9px 15px",
  "& svg": {
    width: 14,
    marginRight: 10,
  },
});

const AddIcon = styled(({ ...HTMLProps }) => (
  <svg {...HTMLProps} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.994141 9.00012C0.994141 9.55275 1.44214 10.0007 1.99477 10.0007H7.83174C7.92385 10.0007 7.99852 10.0754 7.99852 10.1675V16.0045C7.99852 16.5571 8.44651 17.0051 8.99914 17.0051C9.55177 17.0051 9.99977 16.5571 9.99977 16.0045V10.1675C9.99977 10.0754 10.0744 10.0007 10.1665 10.0007H16.0035C16.5561 10.0007 17.0041 9.55275 17.0041 9.00012C17.0041 8.44749 16.5561 7.99949 16.0035 7.99949H10.1665C10.0744 7.99949 9.99977 7.92483 9.99977 7.83272V1.99574C9.99977 1.44311 9.55177 0.995117 8.99914 0.995117C8.44651 0.995117 7.99852 1.44311 7.99852 1.99574V7.83272C7.99852 7.92483 7.92385 7.99949 7.83174 7.99949H1.99477C1.44214 7.99949 0.994141 8.44749 0.994141 9.00012Z"
      fill="currentColor"
    />
  </svg>
))({});

export const Warning = styled(Alert)(({ theme }) => ({
  color: theme.color.headingColor,
  border: "2px solid #F69E1E",
  background: "transparent",
  marginBottom: 20,
  fontSize: 12,
  "& .MuiAlert-icon": {
    color: "#F69E1E",
  },
}));

export const Label = styled("label")({
  cursor: "pointer",
  display: "block",
  fontSize: 12,
});

export const ShareCalc = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr auto 1fr auto 1fr",
  gridGap: "0 10px",
  "&  > span": {
    fontWeight: 600,
    marginTop: 32,
  },
});

export const BoldLabel = styled("b")({
  marginBottom: 5,
});

export const InputContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginRight: 15,
});

export const Link = styled("a")(({ theme }) => ({
  fontWeight: "bold",
  color: theme.color.primary.dark,
  cursor: "pointer",
  whiteSpace: "nowrap",
  "&:hover": {
    textDecoration: "underline",
    color: theme.palette.primary.dark,
  },
}));

export const MuiRadioGroupColumn = styled(RadioGroup)({
  paddingLeft: 12,
  "& label": {
    marginBottom: 26,
  },
  "& .MuiFormControlLabel-label": {
    fontSize: "inherit",
  },
  fontSize: 14,
  fontWeight: 500,
});

export const MuiRadioGroup = styled(RadioGroup)({
  flexDirection: "row",
  fontSize: 14,
  fontWeight: 500,
  outline: 0,
  "& label": {
    marginBottom: 24,
    marginRight: 26,
  },
  "& .MuiFormControlLabel-label": {
    fontSize: "inherit",
  },
});

export const Hover = styled("div")(({ theme }) => ({
  marginRight: 10,
  padding: "5px 10px 5px 20px",
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 5,
  },
}));

export const RadioWrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  marginBottom: 16,
  paddingTop: 5,
  marginLeft: -8,
});

export const StyledRadioGroup = styled(RadioGroup)({
  flexDirection: "row",
  marginLeft: 10,
  fontSize: 14,
  fontWeight: 500,
  "& label": {
    marginBottom: 24,
    marginRight: 26,
  },
  "& .MuiFormControlLabel-label": {
    fontSize: "inherit",
  },
  "& > *:nth-child(2)": {
    marginLeft: 10,
  },
});

export const radioStyles = makeStyles(() => ({
  radio: {
    width: 16,
    height: 16,
    marginRight: 8,
    "& .MuiTypography-root .MuiFormControlLabel-label .MuiTypography-body1": {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    padding: "5px 8px 5px 8px",
    "&:hover": {
      backgroundColor: color.primaryLight,
      cursor: "pointer",
      borderRadius: 5,
    },
  },
}));

export const IconWrapper = styled("div")({
  paddingTop: 1,
  fontSize: 0,
  lineHeight: 0,
});

export const Skeleton = styled("div")({
  borderRadius: 4,
  backgroundColor: Colour.skeletonGrey,
  background: `linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, 0) 60%) ${Colour.skeletonGrey}`,
  backgroundSize: "200% 100%",
  backgroundPositionX: "180%",
  animation: "1s loading ease-in-out infinite",
  height: "1.1rem",
});

export const SearchHeader = styled("div")(() => ({
  flex: 1,
  maxWidth: 400,
  marginRight: 16,
  marginLeft: 16,
}));

export const BoldText = styled("b")({
  fontWeight: 700,
});

export const NoHoldingsMessage = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "58%",
  color: Colour.greymid,
  textAlign: "center",
  fontFamily: "Montserrat",
  fontSize: "18px",
  fontWeight: 600,
});

export const NoResults = styled("h6")({
  width: "100%",
  textAlign: "center",
  margin: 0,
  color: Colour.greymid,
});

/**
 * Anchors to the bottom of parent component. Parent may require a relative position and minimum height
 */
export const GroundContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  position: "absolute",
  bottom: 0,
  width: "100%",
});

export const tableStyles = makeStyles({
  tableHeader: {
    alignItems: "center",
    "& h3": {
      fontSize: 22,
    },
  },
  contactsTable: {
    tableLayout: "fixed",
    borderSpacing: 0,
    width: "100%",
    "& th": {
      paddingTop: 8,
      paddingBottom: 8,
    },
    "& td:first-child, & th:first-child": {
      paddingLeft: 0,
    },
    "& td:last-child, & th:last-child": {
      paddingRight: 0,
    },
  },
  table: {
    tableLayout: "fixed",
    borderSpacing: 0,
    "& th": {
      paddingTop: 8,
      paddingBottom: 8,
    },
    "& td:first-child, & th:first-child": {
      paddingLeft: 0,
      maxWidth: 286,
    },
    "& td:nth-child(2), & th:nth-child(2)": {
      maxWidth: 153,
      width: 153,
      minWidth: 153,
    },
    "& td:last-child, & th:last-child": {
      paddingRight: 0,
    },
  },
  beneficiaryTable: {
    tableLayout: "fixed",
    borderSpacing: 0,
    "& th": {
      paddingTop: 8,
      paddingBottom: 8,
    },
    "& td:first-child, & th:first-child": {
      paddingLeft: 0,
      maxWidth: 286,
      width: 286,
      minWidth: 286,
    },
    "& td:last-child, & th:last-child": {
      textAlign: "right",
      maxWidth: 153,
      width: 153,
      minWidth: 153,
    },
  },
});
